<template>
    <div class="avoid-break" v-disable-all="appt_is_cancelled">
        <div class="dark-text header-w-bg">Services Codes and Billing</div>
        <div class="bordered services bottom-15">
            <div class="">
                <label for="placeOfService"> Place of Service</label>
                <select
                    id="placeOfService"
                    name="placeOfService"
                    :value="place_of_service"
                    @input='set_place_of_service($event.target.value)'
                >
                    <option v-for="place in places" :value="place.id" :key="'place' + place.id">
                        {{ place.id }} - {{ place.office_name }}
                    </option>
                </select>
            </div>
        </div>
        <div id="services">
            <div class="flex top" v-for="s in services" :key="s.data_id">
                <div class="service bottom-15 right-15 flex-1">
                    <label class="bottom-0">Service</label>
                    <Multiselect
                        track_by="service_code_id"
                        :value="getServiceLabel(s.service_code_id)"
                        label="description"
                        @input="select_service(s.data_id, $event)"
                        :options="get_service_options"
                        :disabled="appt_is_cancelled"
                    />
                </div>
                <div class="revenue bottom-15 right-15" style="width: 150px;">
                    <label class="bottom-0" >Revenue</label>
                    <input
                        :id="'revenue ' + s.data_id"
                        name="revenue"
                        type="number"
                        min="0"
                        max="9999"
                        :value="s.revenue_code"
                        @input="set_revenue(s.data_id, $event.target.value)"
                    />
                </div>
                <div class="modifiers flex">
                    <div class="modifier" v-for="(modifier, idx) in 4" :key="modifier+'_'+idx">
                        <label class="bottom-0" >Modifier {{idx+1}}</label>
                        <Multiselect
                            class="bottom-15 block"
                            :options="ModifiersCodeList"
                            :value="getModifierLabel(s.data_id, idx)"
                            @input="set_modifier(s.data_id, idx, $event)"
                            track_by="value"
                            label="text"
                            :disabled="appt_is_cancelled"
                        />
                    </div>
                </div>
                <div class="units bottom-15 right-15">
                    <label class="bottom-0">Units</label>
                    <input
                        type="number"
                        min="0"
                        max="9999"
                        :value="s.units"
                        @input="set_units(s.data_id, $event.target.value)"
                        />
                </div>
                <button
                    v-if='showDeleteButton(s.service_code_id)'
                    class="no-bg text-button bottom-15 top-15"
                    type="button"
                    @click="delete_service(s.data_id)"
                >
                    <span class="material-icons-outlined">delete</span>
                </button>
            </div>
        </div>
        <div class="add-service">
            <button
                class="no-bg dark-text"
                type="button"
                @click="add_service"
                v-if="showAddServiceButton"
            >
                <span class="material-icons purple">add_box</span> Add additional service
            </button>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import { ModifiersCodeList } from '@/util/claimOptions';

export default {

    name: "Services",
    data() {
        return {
            ModifiersCodeList
        }
    },
    computed: {
        ...mapGetters({
            get_service_options: 'progressNote/get_service_options',
            appt_is_cancelled: 'progressNote/appt_is_cancelled',
        }),
        ...mapState({
            place_of_service: state => state.progressNote.place_of_service?.id,
            cancel_no_show_service_codes: state => state.progressNote.globalLists.cancel_no_show_service_codes,
            services: state => state.progressNote.services,
            places: state => state.progressNote.places,
        })
    },
    methods: {
        getServiceLabel(service_code_id) {
            return this.get_service_options.find(s => s.service_code_id === service_code_id);
        },
        getModifierLabel(modifier_id, idx) {
            const value = this.services.find(s => s.data_id === modifier_id).modifier[idx];

            return ModifiersCodeList.find(m => m.value === value);
        },
        showDeleteButton(service_code_id) {
            return !this.cancel_no_show_service_codes.map(s=> s.service_item_id).includes(service_code_id);
        },
        showAddServiceButton(){
            const first_service = this.services[0].service_code_id
            !this.cancel_no_show_service_codes.map(s=> s.service_item_id).includes(first_service)
        },
        set_place_of_service(id) {
            const value = this.places.find(place => place.id === id);
            this.$store.dispatch('progressNote/set_place_of_service', {value});
        },
        select_service(data_id, value) {
            const {service_code_id} = value;
            this.$store.dispatch('progressNote/select_service', {data_id, service_code_id});
        },
        set_revenue(data_id, new_value) {
            if (new_value > 9999){
                new_value = 9999;
                this.$toasted.error("Revenue code cannot be greater than 9999")
            } else if ( new_value < 0){
                new_value = 0;
                this.$toasted.error("Revenue cannot be less than 0")
            }
            this.$store.dispatch('progressNote/set_revenue', {data_id, new_value});
        },
        set_modifier(data_id, index, new_value) {
            this.$store.dispatch('progressNote/set_modifier', {data_id, index, new_value: new_value.value});
        },
        set_units(data_id, new_value) {
            if (new_value > 9999){
                new_value = 9999;
                this.$toasted.error("Units cannot be greater than 9999")
            } else if ( new_value < 0){
                new_value = 0;
                this.$toasted.error("Units cannot be less than $0")
            }
            this.$store.dispatch('progressNote/set_units', {data_id, new_value});
        },
        delete_service(data_id) {
            this.$store.dispatch('progressNote/delete_service', {data_id});
        },
        add_service(){
            this.$store.dispatch('progressNote/add_service');
        }

    },
}
</script>