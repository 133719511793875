<template>
    <div>
        <!--        Save button-->
        <ConfirmButton v-if="!pn.signature_ids.length && changed" :disabled="loading"
                       :updating='!!updating' class="inline-block" @click.prevent="updateRecord($event, 'save')"/>

        <!--        Sign button-->
        <button v-if="show_sign_button" :disabled="loading" @click.prevent="updateRecord($event, 'sign')">Sign</button>

        <div v-if="add_signature_btn">You currently do not have a signature
            <button @click.prevent="to_add_signature">Add Signature</button>
        </div>

        <!--        Signatures-->
        <FormSignatureNew v-if="pn.signature_ids.length > 0" :signature_ids="pn.signature_ids" :user_id="user_id"/>

    </div>
</template>

<script>
import {notes} from "@/util/apiRequests";
import {mapState} from "vuex";
import FormSignatureNew from "@/components/formpieces/FormSignatureNew";
import {intializeState} from "@/store/modules/progressNote";

export default {
    name: "PNSignatures",
    components: {FormSignatureNew},
    data() {
        return {
            changed: false,
            loading: false
        }
    },
    computed: {
        ...mapState({
            updating: state => state.progressNote.updating,
            pn: state => state.progressNote,
            pn_id: state => state.progressNote.pn.id,
            user_id: state => state.user.id,
            original: state => state.progressNote.original,
        }),
        signatures_needed() {
            return this.pn.counselors.map((clinician) => {
                return {
                    type: 'user',
                    id: clinician.id,
                }
            });
        },
        show_sign_button() {

            return this.pn_id && !this.changed && !this.pn.signature_ids.some(s => s.id === this.user_id) && this.pn.user_signature?.id
        },
        add_signature_btn() {

            return this.pn_id && !this.changed && !this.pn.signature_ids.some(s => s.id === this.user_id) && !this.pn.user_signature
        },
    },
    methods: {
        set_updating(value) {
            this.$store.dispatch('progressNote/set_updating', value);
        },
        has_changed(pn) {

            // HELPERS
            const arraysSameOnKeys = (arr1, arr2, keyArr) => {
                if (arr1.length === 0 && arr2.length === 0) {
                    return true;
                }

                if (arr1.length !== arr2.length) {
                    return false
                }

                if (arr1.length && arr2.length && typeof arr1[0] !== typeof arr2[0]) {
                    return false
                }

                if (typeof keyArr === 'string') {
                    keyArr = [keyArr]
                }

                if (arr1 && typeof arr1[0] !== 'object') {
                    arr1 = arr1.map(a => ({id: a}))
                    keyArr = ['id']
                }

                if (arr2 && typeof arr2[0] !== 'object') {
                    arr2 = arr2.map(a => ({id: a}))
                    keyArr = ['id']
                }

                const arr1Keys = arr1.map(a => {
                    let obj = {}
                    keyArr.forEach(key => obj[key] = a[key])
                    return obj
                }) //?
                const arr2Keys = arr2.map(a => {
                    let obj = {}
                    keyArr.forEach(key => obj[key] = a[key])
                    return obj
                }) //?
                return arr1Keys.every(arr1Obj => {
                    return arr2Keys.find(arr2Obj => {
                        return keyArr.every(key => arr1Obj[key] === arr2Obj[key])
                    })
                })
            }

            const assessmentSame = (ass1, ass2) => {
                return Object.keys(ass1).every(key => {
                    return arraysSameOnKeys(ass1[key], ass2[key])
                })
            }

            const risksSame = (risks1, risks2) => {
                const suicideDetails = risks1.details.suicidal === risks2.details.suicidal
                const homicideDetails = risks1.details.homicidal === risks2.details.homicidal
                const suicidal = risks1.suicidal === risks2.suicidal
                const homicide = risks1.homicidal === risks2.homicidal
                const hideRisks = risks1.hideRisks === risks2.hideRisks
                return suicideDetails && homicideDetails && suicidal && homicide && hideRisks
            }

            const treatmentProgSame = (prog1, prog2) => {
                return Object.keys(prog1).every(key => {
                    return arraysSameOnKeys(prog1[key], prog2[key], [key.slice(0, -1), 'checked', 'asam_dimension'])
                })
            }

            const notesSame = (notes1, notes2) => {
                const keys = ['group', 'individual', 'hide']
                return keys.every(key => notes1[key] === notes2[key])
            }

            const servicesSame = (service1, service2) => {
                const service_ids = arraysSameOnKeys(service1, service2, ['service_code_id', 'units', 'revenue_code'])

                if (!service_ids) {
                    return false
                }

                let modifiers_same = true

                service1.forEach(s1 => {
                    const s2 = service2.find(s => s.service_code_id === s1.service_code_id)

                    for (let i in s1.modifier) {
                        if (s1.modifier[i] !== s2.modifier[i]) {
                            modifiers_same = false
                        }
                    }
                })
                return modifiers_same
            }

            // COMPARES

            if (!this.pn_id) {
                this.$set(this, 'changed', true)
                return
            }

            const {
                assessment,
                counselors,
                diagnosis,
                notes,
                place_of_service,
                recommends,
                risks,
                services,
                treatment_prog,
                signature_ids,
            } = pn

            let o = this.original

            o.assessment = o.assessment || []
            o.counselors = o.counselors || []
            o.diagnosis = o.diagnosis || []
            o.notes = o.notes || []
            o.place_of_service = o.place_of_service || intializeState.places[0]
            o.recommends = o.recommends || ""
            o.risks = o.risks || intializeState.risks
            o.services = o.services || []
            o.treatment_prog = o.treatment_prog || intializeState.treatment_prog
            o.signature_ids = o.signature_ids || []


            const changed =
                !arraysSameOnKeys(o.diagnosis, diagnosis, ['selected', 'primary']) ||
                !arraysSameOnKeys(o.counselors, counselors, ['id', 'isPrimary']) ||
                !assessmentSame(o.assessment, assessment) ||
                !risksSame(o.risks, risks) ||
                !treatmentProgSame(o.treatment_prog, treatment_prog) ||
                !notesSame(o.notes, notes) ||
                !(o.recommends === recommends) ||
                !(o.place_of_service.id === place_of_service.id) ||
                !servicesSame(o.services, services) ||
                !arraysSameOnKeys(o.signature_ids, signature_ids, ['id'])

            this.$set(this, 'changed', changed)

        },
        async updateRecord(e, action) {
            e.preventDefault()

            if (!this.updating) {

                this.$set(this, 'loading', true)

                const note_type = "progress"

                if (action === 'save') {
                    const {
                        appt_info,
                        diagnosis,
                        counselors,
                        assessment,
                        risks,
                        treatment_prog,
                        notes: pn_notes,
                        recommends,
                        place_of_service,
                        services,
                        signature_ids
                    } = this.pn

                    if (!services.filter(s => s.service_code_id).length) {
                        this.$toasted.error('Please add at least one service')
                    } else {

                        const res = await this.$api.put(notes.saveNote(
                            this.$route.params.id,
                        ), {
                            action,
                            note_type,
                            appt_info,
                            diagnosis,
                            counselors,
                            assessment,
                            risks,
                            treatment_prog,
                            notes: pn_notes,
                            recommends,
                            place_of_service,
                            services,
                            signature_ids
                        })

                        if (res.status < 200 || res.status >= 300) {
                            this.$toasted.error(res.data.message)
                        } else {
                            this.$toasted.success('Record updated')


                            if (res.data.recId && !parseInt(this.$route.params.id)) {
                                this.$router.replace(`/progress-note/${res.data.recId}`)
                            }

                            const origKeys = ['assessment', 'counselors', 'diagnosis', 'notes', 'place_of_service', 'recommends', 'risks', 'services', 'treatment_prog']

                            origKeys.forEach(key => {
                                this.$store.commit('progressNote/addToOriginal', {[key]: this.pn[key]})
                            })

                            this.$store.dispatch('progressNote/set_addendum', false)
                            this.$set(this, 'changed', false)


                            await this.$store.dispatch('progressNote/set_pn_id', res.data.recId)
                        }
                    }

                } else if (action === 'sign' && this.pn?.user_signature?.id) {
                    this.set_updating(true);

                    const res = await this.$api.put(notes.saveNote(this.$route.params.id), {
                        action,
                        appt_info: this.pn.appt_info,
                    })

                    if (res.status < 200 || res.status >= 300) {
                        this.$toasted.error("Could not sign the note, sorry")
                    } else {
                        this.$toasted.success('Record signed')
                        this.$store.dispatch('progressNote/add_signature', {...this.pn.user_signature, signedAt: new Date()})
                    }
                    this.set_updating(false);
                } else {
                    this.$toasted.error('Unknown action')
                }

            }
            this.$set(this, 'loading', false)

        },
        to_add_signature() {
            this.$router.push(`/user-settings?tab=tab-5`)
        }
    },
    watch: {

        pn: {
            deep: true,
            handler: function (new_val) {
                this.has_changed(new_val)
            }
        },
    }
}
</script>