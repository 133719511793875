<template>
    <div>
        <div class="dark-text header-w-bg">Treatment Progress</div>
        <div v-for="section in sections" :key="section" class="goal-obj-int avoid-break"
        >
            <div class="bottom-5">
                {{ section.toTitleCase() }} Addressed
                <button
                    type="button"
                    class="text-link space-l"
                    @click="add_treatment_prog(section)"
                >
                    + add
                </button>
            </div>
            <div class="bordered bottom-5">
                <div v-if="!treatment_prog[section].length">No {{ section }} added. Click "add" to add a new one</div>
                <div class="flex top bottom-10" v-for="element in treatment_prog[section]" :key="element.data_id">
                    <input
                        class="TreatProg color"
                        type="checkbox"
                        :checked="element.checked"
                        @input="toggle_checked(section, element.data_id)"
                    />

                    <div class="flex-1">
                        <label class="PN-progress-textblock dark-text pad-10"
                                @click="treatment_toggle_edit(section, element.data_id)"
                                v-if="!element.edit"
                                :for="'check'+element.data_id">
                            {{ element[remove_last_character(section)] }}
                        </label>

                        <div v-show="element.edit" class="flex-1 right-10">
                            <ExpandableTextArea
                                class="edit-goal-obj-int"
                                inputClass="block do-not-print-me"
                                placeholder=""
                                label=""
                                :limit=1000
                                inputStyle="min-height:20px;"
                                type="text"
                                :value="temp_edits[section][element.data_id]"
                                @input="temp_edit_changed(section, element.data_id, $event)"
                                :ref="'inp'+element.data_id"/>
                            <div class="align-right top-15">
                                <button class="secondary" @click.prevent="cancel_edit(section, element.data_id)">Cancel</button>
                                <button class="primary" @click.prevent="save(section, element.data_id)">Save</button>
                            </div>
                        </div>
                        <div v-if="element.asam_dimension_text" class="left-10">
                            {{element.asam_dimension_text}}
                        </div>
                    </div>

                    <button class="delete-icon" type="button"
                            @click='delete_treatment_prog(section, element.data_id)'>
                        <span class='material-icons-outlined delete dark-text'>delete_outline</span>
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {generateId} from "@/util/genericUtilityFunctions";

export default {
    name: "TreatmentProgress",
    data() {
        return {
            sections: ['goals', 'objectives', 'interventions'],
            temp_edits: {
                goals: {},
                objectives: {},
                interventions: {}
            },

        }
    },
    computed: {
        ...mapState({
            treatment_prog: state => state.progressNote.treatment_prog
        }),
        goals() {
            return this.treatment_prog.goals;
        },
        objectives() {
            return this.treatment_prog.objectives;
        },
        interventions() {
            return this.treatment_prog.interventions;
        }
    },
    methods: {
        add_treatment_prog(type) {
            const data_id = generateId();
            this.$store.dispatch('progressNote/add_treatment_prog', {type, data_id});
            this.$set(this.temp_edits[type], data_id, '');

            this.$nextTick(()=>{
                this.$refs['inp'+data_id][0].$refs['text_area'].focus();
            })
        },
        toggle_checked(type, data_id) {
            this.$store.dispatch('progressNote/treatment_prog_toggle_checked', {type, data_id});
        },
        delete_treatment_prog(type, data_id) {
            this.$store.dispatch('progressNote/delete_treatment_prog', {type, data_id});
        },
        treatment_toggle_edit(type, data_id) {
            if (this.temp_edits[type][data_id]) {
                // delete this.temp_edits[type][data_id];
            } else {
                const newValue = this.treatment_prog[type].find(e => e.data_id === data_id)[this.remove_last_character(type)] || '';
                this.$set(this.temp_edits[type], data_id, newValue);

                this.$nextTick(()=>{
                    this.$refs['inp'+data_id][0].$refs['text_area'].focus();
                })
            }
            this.$store.dispatch('progressNote/treatment_toggle_edit', {type, data_id});
        },
        remove_last_character(str) {
            return str.slice(0, -1);
        },
        temp_edit_changed(type, data_id, value) {
            this.$set(this.temp_edits[type], data_id, value);
        },
        cancel_edit(type, data_id) {

            if (!this.treatment_prog[type].find(e => e.data_id === data_id)[this.remove_last_character(type)]){
                this.$store.dispatch('progressNote/delete_treatment_prog', {type, data_id});
            } else {

                delete this.temp_edits[type][data_id];

                this.$store.dispatch('progressNote/treatment_toggle_edit', {type, data_id});
            }
        },
        save(type, data_id) {
            const text = this.temp_edits[type][data_id]
            this.$store.dispatch('progressNote/set_treatment_prog_text', {type, data_id, text});
            this.$store.dispatch('progressNote/treatment_toggle_edit', {type, data_id});
        }
    }

}
</script>
