<template>
    <div>
        <div :class="notes.hide && 'do-not-print-me'">
            <div class="dark-text header-w-bg">Notes</div>
            <div class="bottom-5 top-10">
                <span class="space-r">Individual Summary</span>
                <span class="space-r space-l">Hide this section, contains sensitive data</span>
                <Toggle
                    id="hide-summary-data"
                    :labelClass="'switch'"
                    :spanClass="'slider'"
                    :value="notes.hide"
                    class="toggle-switch"
                    @input="toggle_notes_hide"
                />
            </div>

            <div class="textarea-speak">
                <ExpandableTextArea
                    id="progress-data-summary-individual"
                    :limit=4000
                    :value="notes.individual"
                    inputClass="block do-not-print-me"
                    inputStyle="min-height:20px;"
                    label=""
                    placeholder=""
                    type="text"
                    @input="edit_note_text('individual', $event)"
                />
                <button
                    type="button"
                    @click="speechToText('progress-data-summary-individual')"
                >
                    <span class="material-icons-outlined">keyboard_voice</span>
                </button>
            </div>
        </div>

        <div v-if="appt_info.appt_type === 'Group'">
            <span class="space-r">Group Summary</span>
            <span class="space-r space-l">Hide this section, contains sensitive data</span>
            <Toggle
                id="hide-group-summary-data"
                v-model="notes.hide"
                :labelClass="'switch'"
                :spanClass="'slider'"
                class="toggle-switch"
            />
            <div :class="notes.hide && 'do-not-print-me'"
                 class="textarea-speak"
            >

                <ExpandableTextArea
                    id="progress-data-summary-group"
                    :limit=4000
                    :value="notes.group"
                    disabled
                    inputClass="block do-not-print-me"
                    inputStyle="min-height:20px;"
                    label=""
                    placeholder=""
                    type="text"
                    @input="edit_note_text('group', $event)"
                />
                <button
                    disabled
                    type="button"
                    @click="speechToText('progressData-summary-group')"
                >
                    <span class="material-icons-outlined">keyboard_voice</span>
                </button>
            </div>
        </div>
        <div v-if="appt_info.appt_type === 'Family'">
            <span class="space-r">Family Summary</span>
            <span class="space-r space-l">Hide this section, contains sensitive data</span>
            <Toggle
                id="hide-family-summary-data"
                v-model="notes.hide"
                :labelClass="'switch'"
                :spanClass="'slider'"
                class="toggle-switch"
            />
            <div :class="notes.hide && 'do-not-print-me'"
                 class="textarea-speak"
            >

                <ExpandableTextArea
                    id="progress-data-summary-family"
                    :limit=4000
                    :value="notes.family"
                    disabled
                    inputClass="block do-not-print-me"
                    inputStyle="min-height:20px;"
                    label=""
                    placeholder=""
                    type="text"
                    @input="edit_note_text('family', $event)"
                />
                <button
                    disabled
                    type="button"
                    @click="speechToText('progressData-summary-family')"
                >
                    <span class="material-icons-outlined">keyboard_voice</span>
                </button>
            </div>
        </div>
        <div v-if="appt_info.appt_type === 'Couple'">
            <span class="space-r">Couple Summary</span>
            <span class="space-r space-l">Hide this section, contains sensitive data</span>
            <Toggle
                id="hide-couple-summary-data"
                v-model="notes.hide"
                :labelClass="'switch'"
                :spanClass="'slider'"
                class="toggle-switch"
            />
            <div :class="notes.hide && 'do-not-print-me'"
                 class="textarea-speak"
            >

                <ExpandableTextArea
                    id="progress-data-summary-couple"
                    :limit=4000
                    :value="notes.couple"
                    disabled
                    inputClass="block do-not-print-me"
                    inputStyle="min-height:20px;"
                    label=""
                    placeholder=""
                    type="text"
                    @input="edit_note_text('couple', $event)"
                />
                <button
                    disabled
                    type="button"
                    @click="speechToText('progressData-summary-couple')"
                >
                    <span class="material-icons-outlined">keyboard_voice</span>
                </button>
            </div>
        </div>

        <div :class="notes.hide && 'do-not-print-me'">
            <label for="progress-note-plan"> Plan</label>
            <div class="textarea-speak">

                <ExpandableTextArea
                    id="progress-note-plan"
                    :limit=4000
                    :value="recommends"
                    inputClass="block do-not-print-me"
                    inputStyle="min-height:20px;"
                    label=""
                    placeholder=""
                    type="text"
                    @input="edit_note_text('recommends', $event)"
                />
                <button
                    type="button"
                    @click="speechToText('progress-note-plan')"
                >
                    <span class="material-icons-outlined">keyboard_voice</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Notes',

    data() {
        return {
            runtimeTranscription_: '',
            transcript: '',
            transcription_: [],
            lang_: 'en-EN',
        }
    },
    computed: {
        ...mapState({
            appt_info: state => state.progressNote.appt_info,
            notes: state => state.progressNote.notes,
            recommends: state => state.progressNote.recommends
        }),
    },

    methods: {
        edit_note_text(type, text, append = false) {
            this.$store.dispatch('progressNote/edit_note_text', {type, text, append});
        },

        toggle_notes_hide() {
            this.$store.dispatch('progressNote/toggle_notes_hide');
        },

        async speechToText(domID) {
            // initialization of voice recognition
            window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognition = new window.SpeechRecognition();
            recognition.lang = this.lang_;
            recognition.interimResults = true;

            let speechToText = '';
            //current voice recording word
            recognition.addEventListener('result', (event) => {
                const text = Array.from(event.results)
                    .map((result) => result[0])
                    .map((result) => result.transcript)
                    .join('');
                this.runtimeTranscription_ = text;
                this.transcript = text;
                speechToText = text;
                let textArea = document.getElementById(domID);
                textArea.innerHTML = text;
            });

            // end of transcription
            recognition.addEventListener('end', () => {
                this.transcription_.push(this.runtimeTranscription_);
                this.saveSpeechToModel(speechToText, domID);
                this.runtimeTranscription_ = '';
                recognition.stop();
                this.$toasted.error(`Voice recognition is OFF`);

                return speechToText;
            });

            recognition.start();
            this.$toasted.success(`Voice recognition is ON`);
        },
        saveSpeechToModel(text, id) {
            const id_map = {
                'progress-data-summary-individual': 'individual',
                'progress-data-summary-group': 'group',
                'progress-note-plan': 'recommends',
            };

            if (id_map[id]) {
                this.edit_note_text(id_map[id], text, true);
            }
        },
    },

};
</script>