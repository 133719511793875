<template>
    <div class="avoid-break">
        <div class="dark-text header-w-bg">Toxicology</div>
        <div class="bordered flex center">
            <div class="space-r nowrap">Toxicology Report</div>
            <p>
                <strong>{{ has_tox }}</strong>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Toxicology',
    computed:{
        ...mapGetters({
            has_tox: 'progressNote/has_tox'
        })
    },

};
</script>
