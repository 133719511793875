<template>
    <div>
    <div class="flex space-between">
        <div class="name-id">
            <div class="form-client">
                <h2 class="margin-0 pointer" @click="go_to_client">{{ client.last_name }}, {{ client.first_name }}</h2>
                <div class="dark-text" @click="go_to_client">DOB {{ formattedDob() }}</div>
                <div class="pointer blue weight-600 hover-shadow inline-block" @click="go_to_client_docs" >ID# {{ client.id }}</div>
                <div v-if='insurances'>
                    <div class="dark-text" @click="go_to_client" v-for="ins of insurances" :key="ins.id">
                        <span v-if="ins.member_id !== ''"> Insurance# {{ ins.member_id }}</span>
                    </div>
                </div>
<!--                <button class="top-5" type="button" v-if="siggys && parseInt(form_data_id) > 0" @click.prevent="portal2sign" >Send to Portal for Client to Sign</button>-->
            </div>
            <br>
        </div>
        <div class="align-right">
            <div>
                <div >
                    <b>{{ company.company_name }}</b>
                </div>
                <div>
                    <div >{{ company.address }}</div>
                    <div  >{{ company.city }}, {{ company.state }} {{ company.zip }}</div>
                </div>
                <div >{{ company.phone }}</div>
            </div>
        </div>
    </div>
    <div>
        <h2 class="margin-0">{{ pageMeta.title }} - {{ appt_info.appt_type}}</h2>
<!--        <Appointment :rec='rec'/>-->
        <PNApptTime />
    </div>

    <button type="button" @click.prevent="create_addendum"
            v-if="signature_ids.length && !is_addendum">
        Edit & Create Addendum
    </button>
        <div v-if="is_addendum">
        <button class="primary"  @click.prevent="cancel_addendum">
            Cancel Edit
        </button>
            <div class="dark-text header-w-bg">You are now editing the original signed document to create an addendum</div>
        </div>

<!--    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right"-->
<!--            type="button" @click="signature_ids=original_signature_ids, isAddendum =!isAddendum">-->
<!--        Cancel Edit-->
<!--    </button>-->
<!--    <div class="dark-text header-w-bg" v-if="original_signature_ids.length && !signature_ids.length">-->
<!--        You are now editing the original signed document to create an addendum-->
<!--    </div>-->

    <hr class="m-15"/>
    <div class="align-right do-not-print-me">
        <button class="secondary" type="button" @click.prevent="go_to_calendar()">
            Go to Appt
        </button>
        <button v-if="invoice_id" class="secondary" type="button" @click.prevent="go_to_invoice()">
            Go to Invoice
        </button>
        <button class="secondary" type="button" @click.prevent="printView()">
            Print View
        </button>
    </div>
    </div>
</template>

<script>
import PNApptTime from "@/components/progress-note/PNApptTime";
import {mapState} from 'vuex';
import dayjs from 'dayjs';

export default {
    name: "PNHeader.vue",
    components: {
        PNApptTime
    },
    computed: {
        ...mapState({
            client: state => state.progressNote.client,
            company: state => state.progressNote.company,
            appt_info: state => state.progressNote.appt_info,
            insurances: state => state.progressNote.insurances,
            signature_ids: state => state.progressNote.signature_ids,
            is_addendum: state => state.progressNote.is_addendum,
            invoice_id: state => state.progressNote.invoice_id,
        }),
    },
    methods: {
        printView() {
            window.print();
        },
        async go_to_calendar() {
            this.$router.push({ name: 'Calendar', query: { apptId: this.appt_info.appt_id, startTime: this.appt_info.dayt_appt_start }});
        },
        async go_to_invoice() {
            this.$router.push({ name: 'Invoice', params: { invoice_id: this.invoice_id.toString() } });
        },
        async go_to_client() {
            this.$router.push({ name: 'ClientOverview', params: { record_id: this.client.id.toString() } });
        },
        async go_to_client_docs () {
            this.$router.push({ name: 'ClientDocuments', params: { client_id: this.client.id.toString() } });
        },
        formattedDob() {
            return dayjs(this.client.dob).add(1,'day').format('MM/DD/YYYY');
        },
        create_addendum(){
            this.$store.dispatch('progressNote/create_addendum');
        },
        cancel_addendum(){
            this.$store.dispatch('progressNote/cancel_addendum');
        }
    }
}
</script>
