export const bioPsychShortAssessmentOptions = [
    {
        name: "Observations",
        categories: [
            {
                label: 'Behavior',
                name: 'behavior',
                options: [
                    'Not Assessed',
                    'Attentive',
                    'Cooperative',
                    'Sharing',
                    'Supportive',
                    'Resistant',
                    'Withdrawn',
                    'Disruptive',
                    'Irritable',
                    'Restless',
                    'Guarded',
                    'Hostile'
                ],
                req: false
            },
            {
                label: 'Appearance',
                name: 'appearance',
                options: [
                    'Not Assessed',
                    'Well Groomed',
                    'Appropriate Dress',
                    'Inappropiate Dress',
                    'Supportive',
                    'Unkempt',
                    'Disheveled'
                ],
                req: false
            },
            {
                label: 'Mood',
                name: 'mood',
                options: ['Not Assessed', 'Pleasant', 'Calm', 'Euphoric', 'Depressed', 'Anxious', 'Angry'],
                req: false
            },
            {
                label: 'Affect',
                name: 'affect',
                options: ['Not Assessed', 'Appropriate', 'Flat', 'Intense', 'Inappropriate', 'Labile', 'Blunted'],
                req: false
            },
            {
                label: 'Speech',
                name: 'speech',
                options: [
                    'Not Assessed',
                    'Normal',
                    'Slow',
                    'Slurred',
                    'Quiet',
                    'Hyperverbal',
                    'Rapid',
                    'Pressured',
                    'Loud'
                ],
                req: false
            },
            {
                label: 'Motor Activity',
                name: "motor",
                options: [
                    'Not Assessed',
                    'Normal',
                    'Restless',
                    'Tics',
                    'Slowed',
                    'Other'
                ],
                req: false
            },
            {
                label: 'Eye Contact',
                name: "eye",
                options: [
                    'Not Assessed',
                    'Normal',
                    'Intense',
                    'Avoidant',
                    'Other'
                ],
                req: false
            },
            {
                label: 'Attention',
                name: "attention",
                options: [
                    'Not Assessed',
                    'Normal',
                    'Distracted',
                    'Other'
                ],
                req: false
            }
        ]
    },
    {
        name: "Perceptions and Thoughts",
        categories: [
            {
                label: 'Delusions',
                name: "delusions",
                options: [
                    'Not Assessed',
                    'None',
                    'Grandiose',
                    'Paranoid',
                    'Religious',
                    'Other'
                ],
                req: false
            },
            {
                label: 'Hallucinations',
                name: "hallucinations",
                options: [
                    'Not Assessed',
                    'None',
                    'Auditory',
                    'Visual',
                    'Other'
                ],
                req: false
            },
            {
                label: 'Other Perceptions',
                name: "other",
                options: [
                    'Not Assessed',
                    'None',
                    'Derealization',
                    'Depersonalization',
                    'Other'
                ],
                req: false
            },
        ]

    },
    {
        name: "Cognition",
        categories: [
            {
                label: 'Oriented To',
                name: "oriented",
                options: [
                    'Not Assessed',
                    'Person',
                    'Place',
                    'Time',
                    'Situation',
                    'Object'
                ],
                req: false
            },
            {
                label: 'Thought Process',
                name: "thought",
                options: [
                    'Not Assessed',
                    'Normal',
                    'Tangential',
                    'Word Salad',
                    'Loose',
                    'Preservation',
                    'Circumstantial'
                ],
                req: false
            },
            {
                label: 'Memory Impairments',
                name: "memory",
                options: [
                    'Not Assessed',
                    'None',
                    'Short-Term',
                    'Long-Term',
                    'Other'
                ],
                req: false
            }
        ]
    },
]

export const progressNoteAssessmentOptions = [
    {
        name: 'Observations',
        categories: [
            {
                deselect_checkbox_option: 'Not Assessed',
                label: 'Behavior',
                name: 'behavior',
                uncheck_all: 'Not Assessed',
                options: [
                    'Not Assessed',
                    'Attentive',
                    'Cooperative',
                    'Sharing',
                    'Supportive',
                    'Resistant',
                    'Withdrawn',
                    'Disruptive',
                    'Irritable',
                    'Restless',
                    'Guarded',
                    'Hostile'
                ]
            },
            {
                deselect_checkbox_option: 'Not Assessed',
                label: 'Appearance',
                name: 'appearance',
                uncheck_all: 'Not Assessed',
                options: [
                    'Not Assessed',
                    'Well Groomed',
                    'Appropriate Dress',
                    'Inappropiate Dress',
                    'Supportive',
                    'Unkempt',
                    'Disheveled'
                ]
            },
            {
                deselect_checkbox_option: 'Not Assessed',
                label: 'Mood',
                name: 'mood',
                uncheck_all: 'Not Assessed',
                options: ['Not Assessed', 'Pleasant', 'Calm', 'Euphoric', 'Depressed', 'Anxious', 'Angry']
            },
            {
                deselect_checkbox_option: 'Not Assessed',
                label: 'Affect',
                name: 'affect',
                uncheck_all: 'Not Assessed',
                options: ['Not Assessed', 'Appropriate', 'Flat', 'Intense', 'Inappropriate', 'Labile', 'Blunted']
            },
            {
                deselect_checkbox_option: 'Not Assessed',
                label: 'Speech',
                name: 'speech',
                uncheck_all: 'Not Assessed',
                options: [
                    'Not Assessed',
                    'Normal',
                    'Slow',
                    'Slurred',
                    'Quiet',
                    'Hyperverbal',
                    'Rapid',
                    'Pressured',
                    'Loud'
                ]
            },
            {
                deselect_checkbox_option: 'Not Assessed',
                label: 'Thought Process',
                name: 'thought',
                uncheck_all: 'Not Assessed',
                options:
                    [
                        'Not Assessed',
                        'Normal',
                        'Tangential',
                        'Word Salad',
                        'Loose',
                        'Preservation',
                        'Circumstantial'
                    ]
            }
        ]
    }
]

export const bioPsychShortInsightOptions = [ 'Not Assessed', 'Good', 'Fair', 'Poor' ]
