<template>
    <div>
        <div v-if="!edit_mode" class="dark-text">
            <div>
                {{ formattedDate }} {{ startTime }} - {{ endTime }}, {{ duration }} minutes
                <button
                    v-if="!edit_mode"
                    class="no-bg edit pad-5"
                    type="button"
                    @click="start_edit"
                >
                    <span class="material-icons-outlined blue">edit</span>
                </button>
            </div>
        </div>
        <div>
            <div v-if="edit_mode" class="flex bottom edit-apt-info top-5 bottom-20">
                <div class="right-10">
                    <label for="pn-apptDate">Date</label>
                    <input
                        id="pn-apptDate"
                        v-model='new_date'
                        name="pn-apptDate"
                        type="date"
                    />
                </div>
                <div>
                    <label for="pn-apptStartTime">Start</label>
                    <input
                        id="pn-apptStartTime"
                        v-model='new_start_time'
                        name="pn-apptStartTime"
                        type="time"
                    />
                </div>
                <div class="pad-10 dash-line">—</div>
                <div class="right-10">
                    <label for="pn-apptEndTime">End</label>
                    <input
                        id="pn-apptEndTime"
                        v-model='new_end_time'
                        name="pn-apptEndTime"
                        type="time"
                    />
                </div>
                <button type="button" @click="end_edit">Done</button>
            </div>
        </div>

        <div class="flex center dark-text">
            <div class="nowrap right-10">Session # {{ sessions }}:
                <b>{{ appt_info.appt_status || "Has not been attended" }}</b></div>
        </div>
    </div>

</template>

<script>
import {mapState, mapGetters} from 'vuex';
import dayjs from 'dayjs';
import {appts} from "@/util/apiRequests";

export default {
    name: 'Appointment',

    data() {
        return {
            new_date: '',
            new_start_time: '',
            new_end_time: '',
            edit_mode: false,
        }
    },

    computed: {
        ...mapState({
            appt_info: state => state.progressNote.appt_info,
            dayt_appt_start: state => state.progressNote.appt_info.dayt_appt_start,
            dayt_appt_end: state => state.progressNote.appt_info.dayt_appt_end,
            appts: state => state.progressNote.appts
        }),
        ...mapGetters({sessions: 'progressNote/sessions'}),
        startTime() {
            return this.formatTime(this.dayt_appt_start);
        },
        endTime() {
            return this.formatTime(this.dayt_appt_end);
        },
        formattedDate() {
            return dayjs(this.dayt_appt_start).format('MM/DD/YYYY');
        },
        duration() {
            return dayjs(this.dayt_appt_end).diff(dayjs(this.dayt_appt_start), 'minute');
        },
    },

    methods: {
        formatTime(str, forInput = false) {
            return !forInput ? dayjs(str).format('h:mm A') : dayjs(str).format('HH:mm');
        },
        start_edit() {
            this.edit_mode = true;
            this.new_date = dayjs(this.dayt_appt_start).format('YYYY-MM-DD');
            this.new_start_time = this.formatTime(this.dayt_appt_start, true);
            this.new_end_time = this.formatTime(this.dayt_appt_end, true);
        },
        async end_edit() {
            this.edit_mode = false;
            if (this.new_end_time < this.new_start_time) {
                this.$toasted.error('End time must be after start time');
                return
            }
            const date = dayjs(this.new_date)
            const start = date.set('hour', this.new_start_time.split(':')[0]).set('minute', this.new_start_time.split(':')[1]);
            const end = date.set('hour', this.new_end_time.split(':')[0]).set('minute', this.new_end_time.split(':')[1]);

            const initialStart = dayjs(this.dayt_appt_start);
            const initialEnd = dayjs(this.dayt_appt_end);

            if (start.diff(initialStart, 'minute') !== 0 || end.diff(initialEnd, 'minute') !== 0) {
                const payload = {
                    changes: {
                        dayt_appt_start: start.toDate(),
                        dayt_appt_end: end.toDate()
                    },
                    eventMetadata: this.appt_info,
                    changeType: "OnlyCurrent"
                }

                const res = await this.$api.put(appts.batchUpdate(), payload);

                if (res.status >= 200 && res.status < 300) {
                    this.$toasted.success("Appointment successfully updated")
                    await this.$store.dispatch("progressNote/set_appt_times", {
                        start: start.toISOString(), end: end.toISOString()
                    });

                } else {
                    this.$toasted.error("Error updating appointment")
                }
                this.new_date = '';
                this.new_start_time = '';
                this.new_end_time = '';
            }
        },
    },
}

</script>