<template>
    <div>
        <div class="dark-text header-w-bg">Diagnosis</div>
        <div class="bordered">
            <div
                class="flex center has-primary-label bottom-10 max800"
                v-for="(diag) in diagnosis"
                :key="diag.data_id"
                >
                <label class="primary-label right-10 bottom-0" :for="'radio_' + diag.data_id">{{
                        diag.primary ? 'Primary' : ''
                    }}</label>
                <input class="color"
                    type="radio"
                    name="diag"
                    :value="diag.data_id"
                    v-model="primaries"
                    @input="set_diagnosis_primary(diag.data_id)"
                    :id="'radio_' + diag.data_id"
                    />
                <Multiselect
                    class="max800"
                    :options="dx_codes"
                    track-by="id"
                    label="description"
                    :key="'select_' + diag.data_id"
                    @input="set_code(diag.data_id ,$event.id)"
                    :value="getLabel(diag.selected)"
                    >
                </Multiselect>
                <button class="delete-icon left-5" type='button' @click.prevent="remove(diag.data_id)">
                    <span class="button material-icons-outlined">delete</span>
                </button>
            </div>
            <button class="add-service_btn no-bg dark-text add top-10" @click.prevent="add" type='button'>
                <span class="material-icons purple">add_box</span> Add additional DX / ICD code
            </button>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';


export default {
    name: 'DiagnosisList',

    methods: {
        add() {
            this.$store.dispatch("progressNote/add_diagnosis_code")
        },
        remove(data_id) {
            this.$store.dispatch("progressNote/remove_diagnosis_code", data_id)
        },
        set_code(data_id, id) {
            this.$store.dispatch("progressNote/set_diagnosis_code", {data_id, id})
        },
        set_diagnosis_primary(data_id) {
            this.$store.dispatch("progressNote/set_diagnosis_primary", data_id)
        },

        getLabel(id) {
            return this.dx_codes.find(dx => dx.id === id)
        },

    },
    computed: {
        ...mapGetters({
            dx_codes: 'progressNote/dx_codes',
        }),
        ...mapState(
            {
                diagnosis: (state) => state.progressNote.diagnosis,
            }
        ),
        primaries: {
            set(data_id) {
                this.set_diagnosis_primary(data_id)
            },
            get() {
                return this.diagnosis.find(dx => dx.primary === true).data_id;
            }
        },
    }
}
</script>
