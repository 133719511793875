<template>
    <div class="">
        <div class='break-before dark-text header-w-bg do-not-print-me'>Mental Status Assessment</div>
        <div class='flex do-not-print-me'>
            <span class='toggle-label right-10'>Set all categories to within normal limits</span>
            <Toggle
                id='normal-limits'
                class='toggle-switch'
                @input='toggleNormalize'
                :labelClass="'switch'"
                :spanClass="'slider'"
                :value='isNormalized'
            />
            <b> Yes </b>
        </div>

        <!-- section name   -->
        <div class="top-15 avoid-break">
            <div class=""><b class="dark-text">Observations</b></div>
            <hr class="top-0 bottom-10"/>
            <!--   category name  -->
            <div class='flex wrap colx3 assessment'>
                <div class="avoid-break" v-for='( category , key) in checkboxes'
                     :key='category.title'>
                    <div>{{ category.title }}</div>
                    <!--   checkbox items    -->
                    <div class='bordered checkboxes'>
                        <div class="" v-for='(optn, idx) in category.optns' :key='optn+idx'>
                            <input class="color"
                                type='checkbox'
                                @input='handleToggle(key, optn)'
                                :checked="isChecked(key, optn)"
                            />
                            <label class='bottom-5'> {{ optn }}</label>
                        </div>
                    </div>
                    <!--                    <div v-if='category.req' style='text-align:right; color:red;'><b>Required</b></div>-->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'Assessments',
    data() {
        return {
            normalized: false,
            normal: {
                behavior: ['Attentive', 'Cooperative'],
                appearance: ['Well Groomed', 'Appropriate Dress'],
                mood: ['Pleasant', 'Calm'],
                affect: ['Appropriate'],
                speech: ['Normal'],
                thought: ['Normal']
            },
            checkboxes: {
                behavior: {
                    title: 'Behavior',
                    optns: ['Not Assessed',
                        'Attentive',
                        'Cooperative',
                        'Sharing',
                        'Supportive',
                        'Resistant',
                        'Withdrawn',
                        'Disruptive',
                        'Irritable',
                        'Restless',
                        'Guarded',
                        'Hostile'
                    ],
                },
                appearance: {
                    title: 'Appearance',
                    optns: ['Not Assessed',
                        'Well Groomed',
                        'Appropriate Dress',
                        'Inappropriate Dress',
                        'Supportive',
                        'Unkept',
                        'Disheveled'
                    ],
                },
                mood: {
                    title: 'Mood',
                    optns: ['Not Assessed', 'Pleasant', 'Calm', 'Euphoric', 'Depressed', 'Anxious', 'Angry'
                    ],
                },
                affect: {
                    title: "Affect",
                    optns: ['Not Assessed', 'Appropriate', 'Flat', 'Intense', 'Inappropriate', 'Labile', 'Blunted'
                    ],
                },
                speech: {
                    title: 'Speech',
                    optns: ['Not Assessed',
                        'Normal',
                        'Slow',
                        'Slurred',
                        'Quiet',
                        'Hyperverbal',
                        'Rapid',
                        'Pressured',
                        'Loud'
                    ],
                },
                thought: {
                    title: 'Thought Process',
                    optns: ['Not Assessed',
                        'Normal',
                        'Tangential',
                        'Word Salad',
                        'Loose',
                        'Preservation',
                        'Circumstantial'
                    ]
                }
            },
        }
    },
    computed: {
        ...mapState({
            assessment: state => state.progressNote.assessment
        }),
        isNormalized() {
            for (let key in this.assessment) {
                if (this.assessment[key].length !== this.normal[key].length) {
                    return false
                }
                for (let optn of this.assessment[key]) {
                    if (!this.normal[key].includes(optn)) {
                        return false
                    }
                }
            }
            return true
        }
    },
    methods: {
        toggleNormalize() {
            if (!this.isNormalized) {
                this.$store.dispatch('progressNote/normalize_assessment', this.normal)
            } else {
                this.$store.dispatch('progressNote/clear_assessment')
            }
        },
        handleToggle(category, optn) {
            this.$store.dispatch('progressNote/toggle_assessment', {category, optn})
        },
        isChecked(category, optn) {
            return this.assessment[category].includes(optn) || false
        },
    }
};
</script>