<template>
    <div>
        <!-- TAB INPUTS -->
        <input id="tab-1" checked class="page-tab-input" name="page-tab-input" type="radio"/>
        <input id="tab-2" class="page-tab-input" name="page-tab-input" type="radio"/>
        <input id="tab-3" class="page-tab-input" name="page-tab-input" type="radio"/>
        <input id="tab-4" class="page-tab-input" name="page-tab-input" type="radio"/>


        <!-- TABS CONTAINER -->
        <div class="user-settings-header page-tabs-container">
            <label class="page-tab" for="tab-1">Current Progress Note</label>
            <label class="page-tab" for="tab-2">Client's Note History</label>
        </div>

        <!-- TABBED PAGE SECTIONS -->
        <section class="tabbed-page progress-note">
            <div v-if="!loading" v-disable-all="!pageMeta.permissions.edit" :class="pageMeta.component_name.toLowerCase()+'-page'"
                 class="rec-data-div">
                <form @submit.prevent="updateRecord">
                    <PNHeader/>
                    <PNDiagnosisList/>
                    <PNCounselors/>
                    <PNAssessments/>
                    <PNRisks/>
                    <PNToxicology/>
                    <PNTreatmentProgress/>
                    <PNNotes/>
                    <PNServices/>
                    <PNSignatures ref="sigs"/>
                </form>
                <VIfWorkableModal
                    v-if="updating"
                    :hasCloseButton="false"
                    data-html2canvas-ignore="true"
                >
                    <SignConvertPdfModal />
                </VIfWorkableModal>
            </div>
        </section>
        <section class="tabbed-page client-note-history">
            <div class="bottom-20 dark-text">
                <h2 class="margin-0">
                    {{ pn.client.last_name }}, {{ pn.client.first_name }}
                </h2>
                ID#: {{ pn.client.id }}, DOB {{ clientDOB }}
            </div>

            <h3 class="margin-0">View Previous Notes</h3>
            <div class="bottom-30">
                Only signed and completed notes available for view, no drafts. If addendum needed for previous note, go
                to client’s completed forms in the
                <a class="weight-600">
                    <router-link :to="{ name: 'ClientDocuments', params: { client_id: this.pn.client.id } }">
                        Documents & Forms tab of their profile
                    </router-link>
                </a>
                and select “see full list”.
            </div>
            <div v-if="pn.note_history.length < 1"><b>No previous Progress Notes on file for this client.</b></div>
            <div v-for="(note, noteKey) in pn.note_history" :key="noteKey" class="bottom-10 weight-600">
                <a href="" @click.prevent="getFile({ id: note.id })">
                    {{ date_time(note.dayt_create) }} - Progress Note - {{ pn.client.last_name }},
                    {{ pn.client.first_name }}.pdf
                </a>
            </div>
        </section>
    </div>
</template>

<script>
//import form pieces
import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
// import FormClient from '@/components/formpieces/FormClient'
// import FormCompany from '@/components/formpieces/FormCompany.vue';
// import FormSignature from '@/components/formpieces/FormSignatureNew';
// import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
// import {dryalex} from '@/mixins/dryalex'
// import {merge} from 'lodash'
import {file, notes} from "@/util/apiRequests";

import dayjs from 'dayjs';

//import components
// import DiagnosisList from '@/components/general/Diagnosis/DiagnosisList';
// import NoButtonModal from '../../components/general/modals/NoButtonModal';
// import Appointment from '@/components/progress-note/Appointment';
// import Assessments from '../../components/progress-note/Assessments';
// import DeleteServiceModal from '../../components/general/modals/DeleteServiceModal';
// import Counselors from '@/components/progress-note/Counselors';
// import Risks from '@/components/progress-note/Risks';
// import Toxicology from '@/components/progress-note/Toxicology';
// import TreatmentProgress from '@/components/progress-note/TreatmentProgress/TreatmentProgress';
// import Notes from '@/components/progress-note/Notes';
// import Services from '@/components/progress-note/Services';
import PNHeader from '@/components/progress-note/PNHeader';
import PNDiagnosisList from '@/components/progress-note/PNDiagnosisList';
import PNCounselors from "@/components/progress-note/PNCounselors";
import PNAssessments from "@/components/progress-note/PNAssessments";
import PNRisks from "@/components/progress-note/PNRisks";
import PNToxicology from "@/components/progress-note/PNToxicology";
import PNTreatmentProgress from "@/components/progress-note/PNTreatmentProgress";
import PNNotes from "@/components/progress-note/PNNotes";
import PNServices from "@/components/progress-note/PNServices";
import PNSignatures from "@/components/progress-note/PNSignatures";

//importing data to render component
import {mapGetters, mapState} from 'vuex';
// import {ModifiersCodeList} from '@/util/claimOptions';
import {progressNoteAssessmentOptions} from '@/util/assessmentOptions';
import {companies} from '@/util/apiRequests';
import {getSignature} from '@/util/apiRequests';
import {openPdf} from "@/util/pdf";
import {openImage} from "@/util/image";
import {saveFile} from "@/util/genericUtilityFunctions";

export default {
    name: 'ProgressNote',
    components: {

        // TreatmentProgress,
        // Counselors,
        // FormClient,
        // FormSignature,
        // FormCompany,
        // FormSignatureButton,
        // NoButtonModal,
        // DeleteServiceModal,
        VIfWorkableModal,
        // Assessments,
        // DiagnosisList,
        // Risks,
        // Toxicology,
        // Notes,
        // Services,
        // Appointment,
        SignConvertPdfModal,
        PNHeader,
        PNDiagnosisList,
        PNCounselors,
        PNAssessments,
        PNRisks,
        PNToxicology,
        PNTreatmentProgress,
        PNNotes,
        PNServices,
        PNSignatures,
    },
    // props: {
    //     apptGroupUuid: {
    //         type: String,
    //         required: false
    //     },
    // },
    data() {
        return {
            loading: true,
            // updating: false,
            // isEditable: true,
            // user_id: 0,
            // client_id: 0,
            // page_id: 0,
            // rec: {},
            // original_rec: {},
            // signature_ids: [],
            // original_signature_ids: [],
            // showCreatePdfModal: false,
            // api_signs_needed: null,
            // signatures_info: null,
            // client: {},
            // company: {},
            // rows: [],
            // options: [],
            // this_record_id: 0,
            // router_push: 0,
            // disabled: false,
            // record_id: 0,
            // isAddendum: false,
            // servicesComponentKey: 0,

            // ModifiersCodeList,
            progressNoteAssessmentOptions

        };
    },
    computed: {
        ...mapState({
            pn: state => state.progressNote,
            updating: state => state.progressNote.updating,
            client: state => state.progressNote.client
        }),
        ...mapGetters('scheduler', {
            calendarFilters: 'filters'
        }),
        clientDOB() {
            return dayjs(this.client.dob)
                .add(1, 'day')
                .format('MM/DD/YYYY');
        },
    },
    methods: {
        updateRecord(event) {
            event.preventDefault()
            this.$refs['sigs'].updateRecord(event, "save");
        },

        async init() {
            this.$store.commit('progressNote/clearState')

            let client_id = this.$route.query.client_id;
            let appt_id = this.$route.query.appt_id;
            const pn_id = this.$route.params.id;

            this.$store.dispatch('progressNote/set_pn_id', parseInt(pn_id));
            this.$store.commit('setCurrentClientId', {payload: client_id});

            const {data} = await this.$api.get(`/notes/init`, {
                params: {
                    pn_id: pn_id || null,
                    client_id: client_id || null,
                    appt_id: appt_id || null,
                }
            })

            const {
                insurances,
                service_items,
                appt_info,
                cancel_services,
                all_services,
                dx_codes,
                treatment_plan,
                pn,
                clinicians,
                toxicology,
                set_hide_risks,
                client, company, appts, user_signature, note_history, invoice_id
            } = data

            if (parseInt(this.$route.params.id) === 0 && pn && pn.id) {
                this.$router.push(`/progress-note/${pn.id}`)
            }

            await this.$store.commit('progressNote/setGlobals', {key: 'services', value: all_services})
            await this.$store.commit('progressNote/setGlobals', {key: 'dx_codes', value: dx_codes})
            await this.$store.commit('progressNote/setGlobals', {key: 'counselors', value: clinicians})
            await this.$store.commit('progressNote/setGlobals', {
                key: 'cancel_no_show_service_codes',
                value: cancel_services
            })
            await this.$store.commit('progressNote/setMeta', {
                client,
                company,
                appts,
                appt_info,
                insurances,
                user_signature,
                note_history,
                toxicology,
                invoice_id
            })

            await this.$store.dispatch('progressNote/set_services', service_items)

            if (pn) {
                this.$store.dispatch('progressNote/set_pn', pn)
            } else {
                if (treatment_plan) {
                    this.$store.dispatch('progressNote/set_diagnosis_from_tp', treatment_plan)
                    this.$store.dispatch('progressNote/set_counselor_from_tp', treatment_plan)
                    this.$store.dispatch('progressNote/set_goals_from_tp', treatment_plan)
                }

                this.$store.dispatch('progressNote/set_counselors_from_appt', appt_info)
                this.$store.dispatch('progressNote/set_new_appt_service_items', service_items)
                this.$store.dispatch('progressNote/set_new_notes_summary_from_appt', appt_info);
                this.$store.commit('progressNote/setValue', {stateKey: 'risks', objKey: 'hideRisks', value: set_hide_risks})
                this.$store.commit('progressNote/setValue', {stateKey: 'notes', objKey: 'hide', value: set_hide_risks})
            }

            this.loading = false
        },
        async getFile(f) {
            try {
                if (f.id) {
                    let result = await this.$api.get(file.getFile(f.id));
                    if (result.data.file.file_type === 'pdf') {
                        openPdf(result.data?.file?.Body, result.data?.file?.file_name);
                    } else if (result.data.file.originalContentType.includes('image')) {
                        openImage(result.data?.file?.Body);
                    } else {
                        saveFile(
                            result.data?.file?.Body,
                            result.data?.file?.originalContentType,
                            result.data?.file?.file_name
                        );
                    }
                } else {
                    this.$toasted.error('File not found');
                }
            } catch (err) {
                this.$cl(err);
            }
        },
        date_time(date) {
            return dayjs(date).format('MM/DD/YYYY h:mm a');
        },

    },

    async created() {
        await this.$store.dispatch('progressNote/set_pn_id', parseInt(this.$route.params.id))

        await this.init()
    },

    watch: {
        showCreatePdfModal(newVal) {
            if (window.innerWidth >= 768 && !!newVal === true) this.openModalOverlay();
            if (!!newVal === false) {
                this.closeModalOverlay()
            }
        },
        "$route.params.id": {
            handler() {
                this.$store.commit('progressNote/clearState')
                this.init()

            },
            deep: true
        }
    },
    beforeDestroy() {
        this.$store.commit('progressNote/clearState')
    }
};
</script>

