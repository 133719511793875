<template>
    <div>
        <div class="dark-text header-w-bg">Counselors</div>
        <div class="bordered">
            <div class="counselors">
                <div
                    class="flex center has-primary-label bottom-10 max800"
                    v-for="(counselor) in counselors"
                    :key="counselor.data_id"
                >
                    <label class="primary-label margin-0 right-10" for="primaryCounselor">{{
                            counselor.isPrimary ? 'Primary' : ''
                        }}</label>
                    <input
                        class="margin-0 right-15 color"
                        id="i"
                        :key="counselor.data_id"
                        type="radio"
                        :value="counselor.data_id"
                        v-model="primaries"
                        @input="set_counselor_primary(counselor.data_id)"
                    />
                    <Multiselect
                        :options="counselor_optns"
                        :searchable="true"
                        :show-labels="false"
                        label="name"
                        track-by="id"
                        @input="set_counselor(counselor.data_id ,$event.id)"
                        :value="getLabel(counselor.id)"

                    />
                    <button
                        class="delete-icon left-5"
                        type="button"
                        v-if="counselors.length >= 2"
                        @click="removeCounselor(counselor.data_id)"
                    >
                        <span class="material-icons-outlined">delete_outline</span>
                    </button>
                </div>
            </div>
            <!-- Temorarly disable Add additional counselor feature in sprint 2 tasks -->
            <!-- Because of the multiple chat list functionality is not available in tablet and web managment console -->
            
            <!-- <button
                class="no-bg dark-text add left-5"
                type="button"
                @click="addCounselor"
            >
                <span class="material-icons purple">add_box</span> Add additional counselor
            </button> -->
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
    name: 'Counselors',

    methods: {
        addCounselor() {
            this.$store.dispatch('progressNote/add_counselor');
        },
        removeCounselor(data_id) {
            this.$store.dispatch('progressNote/remove_counselor', data_id);
        },
        set_counselor_primary(data_id) {
            this.$store.dispatch('progressNote/set_counselor_primary', data_id);
        },
        set_counselor(data_id, id) {
            this.$store.dispatch('progressNote/set_counselor', {data_id, id});
        },
        getLabel(id) {
            return this.counselor_optns.find(counselor => counselor.id === id);
        }
    },

    computed: {
        ...mapGetters({
            counselor_optns: 'progressNote/counselor_optns',
        }),
        ...mapState({
            counselors: state => state.progressNote.counselors
        }),

        primaries: {
            set(id) {
                this.set_counselor_primary(id);
            },
            get() {
                return this.counselors.find(c => c.isPrimary).data_id;
            }

        }

    },
};
</script>
