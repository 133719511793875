<template>
    <div class = 'avoid-break' :class="risks.hideRisks ? 'do-not-print-me' : ''">
        <div class="dark-text header-w-bg">Risks</div>
        <div class="top-10 bottom-10">
            <span class="right-10">Hide this section, contains sensitive data</span>
            <Toggle
                id="sensitive-data"
                class="toggle-switch"
                :labelClass="'switch'"
                :spanClass="'slider'"
                :value="risks.hideRisks"
                @input="set_risk_hide_fields"
            />
        </div>
        <div class="bordered">
            <div class="flex top bottom-20 top-10">
                <div class="space-r width-70">Homicidal</div>
                <select
                    class="w-100"
                    :value="risks.homicidal"
                    @input="set_risks_bools('homicidal', $event.target.value)"
                >
                    <option v-for="(option, key) in yesOrNo" :key="key" :value="option">
                        {{ option }}
                    </option>
                </select>
                <div v-if="showDetails('homicidal')" class="suicidal-details space-l flex-1">
                    <label class="details" for="homicidal-risk-details">Details</label>
                    <ExpandableTextArea
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        placeholder="Details here about why yes was chosen"
                        id="homicidal-risk-details"
                        :value="risks.details.homicidal"
                        @input="set_risk_details('homicidal', $event)"
                        label=""
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </div>
            </div>
            <div class="flex top top-20 bottom-10">
                <div class="space-r width-70">Suicidal</div>
                <select
                    class="w-100"
                    :value="risks.suicidal"
                    @input="set_risks_bools('suicidal', $event.target.value)"
                >
                    <option v-for="(option, key) in yesOrNo" :key="key" :value="option">
                        {{ option }}
                    </option>
                </select>
                <div v-if="showDetails('suicidal')" class="suicidal-details space-l flex-1">
                    <label class="details" for="suicidal-risk-details">Details</label>
                    <ExpandableTextArea
                        inputClass="block do-not-print-me"
                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                        type="text"
                        placeholder="Details here about why yes was chosen"
                        id="suicidal-risk-details"
                        :value="risks.details.suicidal"
                        @input="set_risk_details('suicidal', $event)"
                        label=""
                        :limit=500
                        inputStyle="min-height:20px;"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'Risks',
    data(){
        return{
            yesOrNo: ['Yes', 'No'],
        }
    },
    computed:{
        ...mapState({
            risks: state => state.progressNote.risks
        }),


    },
    methods: {
        showDetails(field){
            return this.risks[field] === 'Yes'
        },
         set_risks_bools(field, value){
             this.$store.dispatch('progressNote/set_risks_bools', {field, value})
        },
        set_risk_hide_fields(){
            this.$store.dispatch('progressNote/set_risk_hide_fields')
        },
         set_risk_details(field, value){
             this.$store.dispatch('progressNote/set_risk_details', {field, value})
        }
    }
};
</script>